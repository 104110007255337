<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="商品名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="商品分类" prop="menu_ids">
        <el-select v-model="info.menu_ids" placeholder="请选择分类" multiple style="width: 100%">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品图" prop="imgs">
        <SelectImgs :selectNum="5" :selectData="imgs" :selectFunc="imgChoose" :deleteFunc="deleteFunc" :idx="1"></SelectImgs>
        <span style="color: #ccc;">最多5张图，第一张图为默认图</span>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="评分">
        <el-input v-model="info.score" placeholder="请输入评分">
          <template slot="append">0~5分，例如1.4</template>
        </el-input>
      </el-form-item>
      <el-form-item label="规格">
        <el-radio-group v-model="info.is_sku" :disabled="info.id > 0">
          <el-radio :label="0">单规格</el-radio>
          <el-radio :label="1">多规格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="goods_type==1?'日租金':'价格'" v-if="info.is_sku==0">
        <el-input v-model="info.price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="原价" v-if="info.is_sku==0">
        <el-input v-model="info.old_price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="积分" v-if="info.is_sku==0 && goods_type==3">
        <el-input v-model="info.integral" placeholder="请输入积分">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="作者" v-if="goods_type==1">
        <el-input v-model="info.author" placeholder="请输入作者"></el-input>
      </el-form-item>
      <el-form-item label="出版社" v-if="goods_type==1">
        <el-input v-model="info.press" placeholder="请输入出版社"></el-input>
      </el-form-item>
      <el-form-item label="押金" v-if="goods_type==1">
        <el-input v-model="info.deposit" placeholder="请输入押金">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="关联商品" v-if="goods_type==1">
        <el-button type="primary" @click="showModel=true">选择商品</el-button>
        <span v-if="info.goods" class="mg-lf-10 mg-rt-10">{{info.goods.title}}</span>
        <el-button type="warning" @click="clrGoods" class="mg-lf-10" v-if="info.goods && info.goods.id">删除</el-button>
      </el-form-item>
      <el-form-item label="库存" v-if="info.is_sku==0">
        <el-input v-model="info.stock" placeholder="请输入库存"></el-input>
      </el-form-item>
      <el-form-item label="" v-if="info.is_sku==1">
        <div class="mg-bt-5">
          <el-select v-model="specsIdx" placeholder="请选择规格模板" class="mg-rt-5">
            <el-option label="请选择规格模板" value="0"></el-option>
            <el-option
                v-for="child in specsTempList"
                :key="child.id"
                :label="child.title"
                :value="child.id">
            </el-option>
          </el-select>
          <el-button type="primary" @click="useTemp">使用该模板</el-button>
        </div>
        <div v-if="templateInfo && templateInfo.length > 0">
          <div v-for="(item,index) in templateInfo" :key="index">
            <div class="mg-bt-5">
              <el-tag closable effect="dark" size="medium" @close="deleteMain(index)">{{ item.title }}</el-tag>
            </div>
            <el-tag class="mg-rt-10 mg-bt-5 fl" closable v-for="(child,index2) in item.txt" :key="index2" @close="deleteChild(index,index2)">{{ child }}</el-tag>
            <span style="width: 200px; float: left;">
              <el-input placeholder="请输入属性名称" class="font12" v-model="item.word">
                <el-button type="primary" slot="append" class="iv-search" @click="saveChild(index)">添 加</el-button>
              </el-input>
              </span>
            <div class="clr mg-bt-10"></div>
          </div>
        </div>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="showMenu()" v-if="!showInput && templateInfo.length < 3">添加新规格</el-button>
      </el-form-item>
      <el-form-item label="新增" v-if="showInput && info.is_sku==1">
        <el-row>
          <el-col :span="5" class="mg-rt-10"><el-input v-model="specsInfo.title" autocomplete="off" placeholder="请输入规格名"><template slot="prepend">规格名：</template></el-input></el-col>
          <el-col :span="5"><el-input v-model="specsInfo.value" autocomplete="off" placeholder="请输入规格值"><template slot="prepend">规格值：</template></el-input></el-col>
          <el-col :span="3" style="text-align: right">
            <el-button type="primary" @click="saveMain" size="small">确 定</el-button>
            <el-button @click="showInput = false" size="small">取 消</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="" v-if="info.is_sku==1">
        <el-button type="primary" @click="createList">立即生成</el-button>
        <el-table
            :data="tableData"
            :key="tableRandKey"
            ref="rfTable"
            border
            style="width: 92%; margin-top: 10px;"
            size="mini">
          <el-table-column v-for="(item,index) in tableHeader" :key="index"
              :prop="item.field"
              :label="item.title"
              align="center"
              width="140">
          </el-table-column>
          <el-table-column
              prop="img"
              label="图片"
              align="center"
              min-width="120">
            <template slot-scope="scope">
              <SelectImgs :selectNum="1" :selectData="[scope.row.img]" :selectFunc="imgSpecChoose" :idx="scope.$index"></SelectImgs>
              <el-input v-model="scope.row.rk" autocomplete="off" placeholder="请输入价格" style="display: none"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="price"
              :label="goods_type==1?'日租金':'价格'"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="old_price"
              label="原价"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.old_price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column v-if="goods_type==3"
              prop="integral"
              label="积分"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.integral" autocomplete="off" placeholder="请输入积分"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="stock"
              label="库存"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.stock" autocomplete="off" placeholder="请输入库存"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
            <template slot-scope="scope">
              <el-popconfirm
                  title="确认删除该规格吗？"
                  @confirm="deleteSpecs(scope.$index)"
              >
                <el-button slot="reference" type="text" size="small">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择商品" :visible.sync="showModel" custom-class="dialog">
      <goods-list :type="1" :goodsType="2" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import WangEdit from "../../components/WangEdit";
import SelectImgs from '../../components/SelectImgs'
import GoodsList from "@/components/GoodsList";
export default {
  data() {
    return {
      showModel: false,
      tableHeader: [],
      tableData: [],
      menuList: [],
      specsTempList: [],
      specsIdx: '',
      templateInfo: [],
      imgs: [],
      kefu_qrcode: [],
      tableRandKey: 1,
      info: {
        id: 0,
        menu_ids: [],
        imgs: '',
        intro: '',
        price: '',
        old_price: '',
        integral: '',
        stock: '',
        content: '',
        is_sku: 0,
        score: '',
        goods_id: 0,
        goods: false
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入商品名称', trigger: 'blur'}
        ],
        menu_ids: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ]
      },
      showInput: false,
      specsInfo: {
        title: '',
        value: ''
      },
      goods_type: 1
    };
  },
  created() {
    if(this.$route.query.type) {
      this.goods_type = this.$route.query.type
    }
    this.getMenuList()
    this.getSpecsList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    WangEdit,
    GoodsList
  },
  computed: {},
  methods: {
    showMenu() {
      this.showInput = true
    },
    chooseGoods(item) {
      this.info.goods_id = item.id
      this.info.goods = item
      this.showModel = false
    },
    clrGoods() {
      this.info.goods_id = 0
      this.info.goods = false
    },
    saveMain() {
      if(this.specsInfo.title == '') {
        this.fail('请输入规格名')
      } else if(this.specsInfo.value == '') {
        this.fail('请输入属性值')
      } else {
        this.templateInfo.push({title: this.specsInfo.title.trim(), txt: [this.specsInfo.value.trim()]})
        this.clrSpecs()
        this.showInput = false
      }
    },
    saveChild(index) {
      if(!this.templateInfo[index].word) {
        this.fail('请输入属性名称')
      } else if(this.templateInfo[index].txt.indexOf(this.templateInfo[index].word) > -1) {
        this.fail('该属性已存在')
      } else {
        this.templateInfo[index].txt.push(this.templateInfo[index].word.trim())
        this.templateInfo[index].word = ''
      }
    },
    deleteMain(index) {
      this.templateInfo.splice(index,1)
    },
    deleteChild(index,index2) {
      this.templateInfo[index].txt.splice(index2,1)
    },
    clrSpecs() {
      this.specsInfo = {title: '', value: ''}
    },
    setText(txt) {
      this.info.content = txt
    },
    imgChoose(item,idx) {
      var _this = this
      if(idx==1) {
        var pics = this.imgs
        item.forEach(function (child) {
          if(_this.imgs.length < 5) {
            pics.push(child.pic)
          }
        })
        this.imgs = pics
        this.info.imgs = pics
      } else {
        this.kefu_qrcode = [item.pic]
        this.info.kefu_qrcode = item.pic
      }
    },
    imgSpecChoose(item,idx) {
      this.tableData[idx].img = item.pic
      this.tableData[idx].rk = Math.random()
    },
    deleteSpecs(idx) {
      this.tableData.splice(idx,1)
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.imgs))
      imgs.splice(idx,1)
      this.imgs = imgs
      this.info.imgs = imgs
    },
    getMenuList() {
      this.$api.goods.menuList({type:this.goods_type,menu_type:2}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getSpecsList() {
      this.$api.goods.specsTemplateIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.specsTempList = res.data.data
        }
      })
    },
    useTemp() {
      var _this = this
      _this.showInput = false
      this.specsTempList.forEach(function (item) {
        if(item.id == _this.specsIdx) {
          _this.templateInfo = JSON.parse(JSON.stringify(item.content))
        }
      })
    },
    createList() {
      var _this = this
      var tableHeader = []
      var tableBody = []
      var tableData = []
      if(_this.templateInfo) {
        _this.templateInfo.forEach(function (item,index) {
          tableHeader.push({title:item.title,field:'value'+index})
          tableBody.push(item.txt)
        })
      }
      this.fs(tableBody).forEach(function (item,index) {
        var info = {price:'',old_price:'',img:'',stock:'',rk:'',txt:[]}
        if(item instanceof Array) {
          item.forEach(function (child,cindex) {
            eval("info.value"+cindex+"='"+child+"'")
            info.txt.push(child.trim())
          })
        } else {
          info.txt = [item]
          info.value0 = item
        }
        tableData.push(info)
      })
      _this.tableHeader = tableHeader
      _this.tableData = tableData
    },
    fs(array){
      if (array.length < 2) return array[0] || [];
      return array.reduce((total, currentValue) => {
        let res = [];
        total.forEach(t => {
          currentValue.forEach(cv => {
            if (t instanceof Array) 	// 或者使用 Array.isArray(t)
              res.push([...t, cv]);
            else
              res.push([t, cv]);
          })
        })
        return res;
      })
    },
    getInfo() {
      var that = this
      this.$api.goods.goodsInfo({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
          if(that.info.imgs) {
            that.imgs = that.info.imgs
          }
          if(that.info.kefu_qrcode) {
            that.kefu_qrcode = [that.info.kefu_qrcode]
          }
          if(that.info.attrList) {
            that.templateInfo = that.info.attrList
            that.tableHeader = []
            that.templateInfo.forEach(function (item,index) {
              that.tableHeader.push({title:item.title,field:'value'+index})
            })
          }
          if(that.info.specsList) {
            that.tableData = that.info.specsList
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(that.info.is_sku==1 && that.tableData.length < 1) {
            that.fail('请创建商品规格')
            return false
          }
          var param = this.info
          param.goods_type = this.goods_type
          if(param.is_sku==1) {
            param.specsList = that.tableData
            param.attrList = that.templateInfo
          }
          if (this.info.id == 0) {
            this.$api.goods.goodsAdd(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.goods.goodsEdit(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
